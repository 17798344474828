<template>
    <div>
        <div class="">
            <div class="d-flex flex-row flex-wrap justify-content-between align-items-center">
                <div class="breadcrumbactive ml-2">
                    <div>
                        <span class="pointer" @click="goToCompanyList">Company</span>
                        <span>List View</span>
                        <div>View Company</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tabs-content-secondblock bank-info-main mx-2 bg-white mb-30">
            <div class="mb-3">
                <div class="d-flex justify-content-between align-items-center basic-info" style="background-color: #00448b;border-radius: 0px 6px 0 0;">
                    <h6 class="subHeaderTitle my-2 text-white pl-5">
                        <span class="pl-2 fs-14 fw-500 profile-text">Bank Details</span>
                    </h6>
                    <div v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super admin' || loggedInUser.user_role == 'super_admin'" style="margin-right: 10px;">
                        <button type="button" @click="showCreateBank" class="btn btn-add-bank">+ Add Bank</button>
                    </div>
                </div>
                <div class="row" v-if="ajax_call_in_progress">
                    <div class="col-lg-12 py-5 px-3 mt-3">
                        <div class="flex-container">
                            <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                        </div>
                    </div>
                </div>
                <div v-else class="row">
                    <div class="col-lg-12">
                        <div class="infoHeader px-6 pb-4">
                            <div>
                                <div v-if="bank_data.length && !ajax_call_in_progress" class="row flex-wrap align-items-center">
                                    <div v-for="(bank, bank_index) in bank_data" :key="bank_index" class="col-lg-6 col-md-6 col-sm-6 pt-5">
                                        <div class="new-card">
                                            <div v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super admin' || loggedInUser.user_role == 'super_admin'" class="d-flex align-items-center justify-content-between">
                                                <h2 class="fs-14 fw-600 mb-0 text-truncate text-secondary pl-2 pointer" @click="viewBankDetails(bank.id)" :title="bank.account_name || '-'">{{bank.account_name || "-"}}</h2>
                                                <div class="position-relative d-flex align-items-center">
                                                    <i @click="showEditBank(bank.id)" class="icon icon-pencil text-secondary pointer fs-16"></i>
                                                    <i @click="showDeleteConfirm(bank.id)" class="icon icon-delete text-danger pointer fs-18 ml-0"></i>
                                                </div>
                                            </div>
                                            <hr>
                                            <div>
                                                <!-- <h1 class="fs-14 my-2 px-2" style="color: #3f3f3f;">Bank Branch {{bank_index+1}}</h1> -->
                                                <div class="d-flex align-items-center pl-2 pr-3">
                                                    <h2 class="fs-14 fw-500" style="min-width: 90px;">Account No</h2>
                                                    <h2 class="fs-14 fw-500 text-truncate">: &nbsp; {{bank.account_number || "-"}}</h2>
                                                </div>
                                                <div class="d-flex align-items-center pl-2 pr-3">
                                                    <h2 class="fs-14 fw-500" style="min-width: 90px;">Bank Name</h2>
                                                    <h2 class="fs-14 fw-500 text-truncate" :title="bank.bank_name || '-'">: &nbsp; {{bank.bank_name || "-"}}</h2>
                                                </div>
                                                <div class="d-flex align-items-center pl-2 pr-3">
                                                    <h2 class="fs-14 fw-500" style="min-width: 90px;">Branch</h2>
                                                    <h2 class="fs-14 fw-500 text-truncate" :title="bank.bank_branch_name || '-'">:&nbsp; {{bank.bank_branch_name || "-"}}</h2>
                                                </div>
                                                <div class="d-flex align-items-center pl-2 pr-3">
                                                    <h2 class="fs-14 fw-500" style="min-width: 90px;">IFSC</h2>
                                                    <h2 class="fs-14 fw-500 text-truncate" :title="bank.ifsc_code || '-'">:&nbsp;&nbsp;{{bank.ifsc_code || "-"}}</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else class="row flex-wrap align-items-center">
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <div class="text-center my-10">
                                            <img src="/static/images/bank_details_not_found_illus.svg" width="400" />   
                                        </div>
                                        <p class="text-secondary fs-14 text-center">Add Bank details here!</p>
                                    </div>
                                </div>
                                <!-- <div v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super admin' || loggedInUser.user_role == 'super_admin'" class="text-right mt-15">
                                    <button class="btn btn-new-primary" @click="showCreateBank">+ Add Bank</button>
                                </div> -->
                            </div>
                        </div>
                        <div v-if="bank_data.length && !ajax_call_in_progress" class="d-flex justify-content-end align-items-center bg-white py-2 px-4 pb-10" style="border-bottom-left-radius: 5px; border-bottom-right-radius: 5px">
                            <multiselect class="diginew-multiselect"
                                :options="pageLimit" :close-on-select="true" v-model.number="limit"
                                open-direction="top" :show-labels="false" placeholder=""
                                style="width: 4.0em !important;padding-right: 10px!important;padding-top: 10px !important;"></multiselect>
                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/down-arrow-grey.svg" style="margin-left: -20px;">
                            <span class="page-info pr-2 pl-6 fw-500" style="height: 2.4rem;line-height: 2.4rem;color: #303031;font-size: 14px;">Per page</span>
                            <button @click="firstPage()"  :class="{'not-allowed' : skip == 0}" :disabled="skip == 0" class="mt-1 pointer pagination-list">
                                <el-tooltip class="item" effect="dark" content="First Page" placement="top">
                                    <i class="icon-chevron-double-left"></i>
                                </el-tooltip>    
                            </button>
                            <button @click="previousPage()"  :class="{'not-allowed' : skip == 0}" :disabled="skip == 0" class="pagination-list mt-1 ml-2 pointer">
                                <el-tooltip class="item" effect="dark" content="Previous Page" placement="top">
                                    <i class="icon icon-chevron-left"></i>
                                </el-tooltip>    
                            </button>
                            <span class="px-2 fw-500 fs-14 mt-1">Showing {{getSkipCount}} - {{total>skip+limit?skip+limit:total}} of {{total}}</span>
                            <button @click="nextPage()" :class="{'not-allowed' : temp_total == total }" :disabled="temp_total == total" class="pagination-list mt-1 mr-2 pointer">
                                <el-tooltip class="item" effect="dark" content="Next Page" placement="top">
                                    <i class="icon icon-chevron-right"></i>
                                </el-tooltip>    
                            </button>
                            <button @click="lastPage()" :class="{'not-allowed' : temp_total == total}" class="mt-1 pointer pagination-list" :disabled ="temp_total == total">
                                <el-tooltip class="item" effect="dark" content="Last Page" placement="top">
                                    <i class="icon-chevron-double-right"></i>
                                </el-tooltip>    
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <address-delete-popup v-if="delete_confirm" @hideConfirm="hideConfirm" @deleteConfirm="deleteConfirm" modal_name="delete_confirm" ></address-delete-popup>
        <add-bank-details v-if="create_bank" @hideCreateBanksucc="hideCreateBanksucc" @hideCreateBank="hideCreateBank" :mode="mode" :bank_id="bank_id" modal_name="create-bank"></add-bank-details>
        <sweet-modal ref="warning_modal" overlay-theme="dark" icon="warning">{{warning_msg}}</sweet-modal>
        <sweet-modal ref="success_modal" overlay-theme="dark" icon="success">{{success_msg}}</sweet-modal>
    </div>
</template>
<script>
    import moment from "moment";
    import {
        HalfCircleSpinner
    } from "epic-spinners";
    const AddBankDetails = () => import('./BankDetails/AddBankDetails');
    import {
        EventBus
    } from "../eventBus/nav-bar-event.js";
    import { SweetModal } from 'sweet-modal-vue';
    import companies from '../mixins/companies.js';
    const AddressDeletePopup = () => import('./AddressDeletePopup');
    export default {
        props: [],
        components:{
            AddBankDetails,
            HalfCircleSpinner,
            SweetModal,
            AddressDeletePopup,
        },
        data() {
            return {
                create_bank: false,
                bank_data: [],
                ajax_call_in_progress: false,
                warning_msg: '',
                success_msg: '',
                mode: 'add',
                bank_id: '',
                bank_delete: '',
                delete_confirm: false,
                total: 0,
                skip_temp:0,
                temp_total:0,
                skip:0,
                limit: 10,
                pageLimit: [],
            };
        },
        mixins: [companies],
        methods: {
            goToCompanyList() {
                this.$router.push('/company/view/cmpid/' + this.$route.params.company_id);
            },
            previousPage() {
                this.temp_total = 0
                this.skip_temp = 0
                if (this.skip == 0) {
                    return;
                }
                this.skip = this.skip - this.limit <= 0 ? 0 : this.skip - this.limit;
                this.getListViewData();
            },
            nextPage() {
                this.skip_temp = this.skip + this.limit > this.total ? this.total : this.skip + this.limit;
                if (this.skip_temp >= this.total) {
                    return;
                }
                this.skip = this.skip_temp;
                this.getListViewData();
            },
            firstPage(){
                this.temp_total = 0
                this.skip = 0
                this.limit = this.limit
                this.list_view_data = [] 
                this.getListViewData();
            },
            lastPage() {
                this.skip_temp = 0
                this.skip = 0
                this.temp_total = this.total
                let no_of_pages = Math.ceil(this.total/this.limit)
                this.skip_temp = (no_of_pages * this.limit) - this.limit
                if (this.skip_temp >= this.total) {
                    return;
                }
                this.skip = this.skip_temp
                this.getListViewData();
            },
            hideConfirm() {
                this.delete_confirm = false;
            },
            showDeleteConfirm(id) {
                this.bank_delete = id
                this.delete_confirm = true;
                setTimeout(() => {
                    this.$modal.show('delete_confirm');
                }, 500);
            },
            deleteConfirm() {
                if(this.bank_delete === undefined || this.bank_delete === null || this.bank_delete === '') {
                    this.delete_confirm = false;
                } else {
                    this.deleteBank(this.bank_delete);
                }
            },
            moment(date) {
                return moment(date);
            },
            hideCreateBank() {
                this.create_bank = false;
                if(this.mode != 'view') {
                    // this.getListViewData();
                }
            },
            hideCreateBanksucc() {
                this.create_bank = false;
                if(this.mode != 'view') {
                    this.getListViewData();
                }
            },
            showCreateBank() {
                this.create_bank = true;
                this.mode = 'add';
                setTimeout(() => {
                    this.$modal.show('create-bank');
                }, 500);
            },
            showEditBank(id) {
                this.bank_id = id;
                this.create_bank = true;
                this.mode = 'edit';
                setTimeout(() => {
                    this.$modal.show('create-bank');
                }, 500);
            },
            viewBankDetails(id) {
                this.bank_id = id;
                this.create_bank = true;
                this.mode = 'view';
                setTimeout(() => {
                    this.$modal.show('create-bank');
                }, 500);
            },
            async getListViewData() {
                this.ajax_call_in_progress = true;
                try {
                    let params = {
                        skip: this.skip,
                        limit: this.limit == "" || this.limit == 0 || this.limit < 0 ? 10 : this.limit,
                        company_id: this.$route.params.company_id
                    };
                    let response = await this.getBankDetails(params);
                    if(response.status_id == 1) {
                        this.bank_data = response.response
                        this.total = response.total_count
                    }
                    this.ajax_call_in_progress = false;
                }
                catch(err) {
                    this.ajax_call_in_progress = false;
                }
            },
            async deleteBank(id) {
                try {
                    let response = await this.deleteBankDetailsById(id);
                    if(response.status_id == 1) {
                        this.success_msg = response.message
                        this.$refs.success_modal.open();
                        setTimeout(() => {
                            this.$refs.success_modal.close();
                            this.getListViewData();
                        }, 2000);
                    }
                }
                catch(err) {
                    // console.log(err);
                    this.warning_msg = err;
                    this.$refs.warning_modal.open();
                }
            },
            goToBankDetailsView(id) {
                this.$router.push({ path: "/company/view/cmpid/"+this.$route.params.company_id, query: {bank: id} })
            }
        },
        props: [],
        mounted() {
            this.getListViewData();
        },
        created() {
            for (let i = 10; i <= 50; i+=10) {
                this.pageLimit.push(i);
            }
        },
        watch: {
            limit: function(){
                this.getListViewData()
            },
        },
        computed: {
            getSkipCount(){
                if(this.total == 0) {
                    return 0
                } else {
                    let skip_count = this.skip>this.total?this.total:this.skip+1;
                    return skip_count;
                }
            },
            loggedInUser() {
                return this.$store.getters.loggedInUser;
            },
        },
    };
</script>
<style scoped>
    .new-card {
        padding: 3.5px 5.5px 12.8px 11.5px;
        border-radius: 2.5px;
        box-shadow: 0 0 10px 0 #e5e5e5;
        background-color: var(--white);
    }
    .bank-info-main{
        min-height: 500px
    }
    .btn-add-bank {
        color: #058125;
        border: 1px solid #058125;
        background-color: #fff;
        padding: 5px 10px !important;
        line-height: 1;
        font-weight: 500;
        height: auto;
    }
    .not-allowed{
        cursor: no-drop !important;
    }
</style>